<template>
  <standard-page :definition="$DEFINITIONS.provenance.process" :status="processDefinition.status" :title="processDefinition.name" sub_title="Processes" v-if="processDefinition">
    <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'provenances-home'}">Provenance</router-link>
      </span>
      <span>
        <router-link :to="{ name: 'provenances-registries'}">Registries</router-link>
      </span>
      <span v-if="registry">
        <router-link :to="{ name: 'processdefinitions'}">Registry: {{registry.name | truncate(10, '.....')}}</router-link>
      </span>
      <span v-if="processDefinition">
        <router-link :to="{ name: 'process-definition-steps'}">Process Definition: {{processDefinition.name | truncate(10, '.....')}}</router-link>
      </span>
      <span>Processes</span>
    </template>
    <template v-slot:content>
      <data-table :api="api" :columns="columns" :row-click="view" :server-side="true" @ready="onReady" list-key="processes" table-id="process_table" total-key="total">
      </data-table>
        <delete-modal :service="'provenance'" :context="'Process'" :table="table" :url="`provenance/registries/${registryid}/definitions/${processdefinitionid}/processes/${processIdtoDel}`" v-if="processIdtoDel"></delete-modal>
    </template>
  </standard-page>
</template>

<script>

export default {
  name: "Processes",
  props: ['registryid', 'processdefinitionid'],
  data() {
    return {
      registry: null,
      processDefinition: null,
        processIdtoDel: null,
      api: `${this.$provenance.defaults.baseURL}/provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes`,
      columns: [
        { type: 'name' },
        { type: 'provenanceProcessStatus' },
        { type: 'created' },
        { type: 'extrinsic' },
          {
              type: 'action',
              defaultContent: `<div class="offset-2 btn-group btn-icon-list">
                                    <span class="text-dark qr mg-r-10" style="cursor: pointer" title="View QR">
                                         <i class="fa fa-qrcode fa-lg"></i>
                                    </span>
                                    ${this.$StandardButtons.deleteButton('#confirmationmodal', 'Delete Process')}
                                </div>`
                      // `<span class="text-dark qr mg-r-10" style="cursor: pointer" title="View QR">
                      //               <i class="fa fa-qrcode fa-lg"></i>
                      //           </span>
                      //           `
          }
      ],
      table: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.getRegistry();
      await this.getProcessDefinition();
    },
      onReady(table) {
          this.table = table;
          let self = this;

          $(async function () {
              $('#process_table tbody').on('click', '.qr', function () {
                  let process = table.row($(this).parents('tr')).data();
                  self.$router.push({name: 'process-qr-print',  params: { registryid: process.registry, processdefinitionid: process.process_definition, processid: process.process }})
              });

              $('#process_table tbody').on('click', '.delete', function () {
                  let process = table.row($(this).parents('tr')).data();
                  self.setRegistryIdToDelete(process.process);
                  table.ajax.reload();
              });
          });
      },
    async getRegistry() {
      try {
        let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}`);
        this.registry = data;
      } catch (e) {
        this.$toastr.e("Error retrieving Registry", "Error");
      } finally {

      }
    },
  setRegistryIdToDelete(processid) {
      this.processIdtoDel = processid;
  },
    async getProcessDefinition() {
      try {
        let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}`);
        this.processDefinition = data;
      } catch (e) {
        this.$toastr.e("Error retrieving Process Definition", "Error");
      } finally {

      }
    },
    view(process) {
      this.$router.push({ name: 'process-steps', params: { registryid: this.registryid, processdefinitionid: this.processdefinitionid, processid: process.process } });
    },

  }
}
</script>

<style scoped>
</style>